/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //Prevent multiple clicks on contact form
        $(document).on("click", ".wpcf7-submit", function(e){
          var loader = $(e.target).closest(".wpcf7-form").find(".ajax-loader");
          if(loader.hasClass('is-active') ) {
            e.preventDefault();
              return false;
          }
        });


         var menu_options = {
          menuElement: '.nav-primary',
          menuInnerElement: '.menu-huvudmeny-container'
        };
        $('.nav-primary').fullScreenMenu(menu_options);

        var startfilteryear = $('#yearholder').attr('data-firstyear');
        var endfilteryear = $('#yearholder').attr('data-lastyear');
        for (i=startfilteryear;i>=endfilteryear;i--) {
          var option='<option id="'+i+'" value="year_'+i+'">'+i+'</option>';
          $('#newsyear').append(option);
        }




        $('.everycourse li').each(function(index, el) {
          var coursestring=$(this).html();
          var courseid=$(this).attr('data-id');
          var selected=$('.everycourse').attr('data-selected');
          var option='<option id="'+courseid+'" value="'+coursestring+'">'+coursestring+'</option>';
          $('#courselist').append(option);
          if(courseid===selected) {
            $('#courselist').val(coursestring);
          } 
        });




        if ( $( '#mapcontainer1' ) && $( '#mapcontainer1' ).length ) {
          var officeMap1 = new GMaps({
            div: '#mapcontainer1',
            lat: $('#mapcontainer1').attr('data-lat'),
            lng: $('#mapcontainer1').attr('data-lon'),
            scrollwheel: false,
            styles: [
                {
                    "featureType": "poi.business",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
          });

          officeMap1.addMarker({
            lat: $('#mapcontainer1').attr('data-lat'),
            lng: $('#mapcontainer1').attr('data-lon'),
          });
        }

        if ( $( '#mapcontainer2' ) && $( '#mapcontainer2' ).length ) {
          var officeMap2 = new GMaps({
            div: '#mapcontainer2',
            lat: $('#mapcontainer2').attr('data-lat'),
            lng: $('#mapcontainer2').attr('data-lon'),
            scrollwheel: false,
            styles: [
                {
                    "featureType": "poi.business",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
          });

          officeMap2.addMarker({
            lat: $('#mapcontainer2').attr('data-lat'),
            lng: $('#mapcontainer2').attr('data-lon'),
          });
        }

        $('.button.showmorecustomers').on('click', function(event) {
          event.preventDefault();
          /* Act on the event */
          $(this).parent().find('.customercarousel').addClass('active');
          $(this).hide();
        });

        $('.customercarousel').html($('.customercarousel .carousel-cell').sort(function(){
          return Math.random()-0.5;
        }));

        $('.submenutoggle').on('click', function(event) {
          event.preventDefault();
          /* Act on the event */
          var isActive = $(this).hasClass('active');

          $('.submenutoggle').removeClass('active');
          $('.sub-menu-wrap').removeClass('active');
          
          if( !isActive ) {
            $(this).addClass('active');
            $(this).parent().find('.sub-menu-wrap').addClass('active');
          }
        });

        $('.moretogglebutton').on('click', function(event) {
          event.preventDefault();
          /* Act on the event */
          $('.courselist').css('display', 'flex');
          $(this).parent().find('ul.booklist li').removeClass('moretoggle');
          $(this).hide();
        });






		$('.fadeInBlock').each( function(i){
          $(this).attr('data-emergence', 'hidden');
        }); 

        $('.fadeInOnStart').attr('data-emergence','visible');

        emergence.init({
          reset: true
        });

        $('.linkedcard').on('click', function(event) {
          event.preventDefault();
          window.location.href = $(this).find('h2.linktitle a').attr('href');
        });
        $('a.newstag').on('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
          window.location.href = $(this).attr('href');
        });

        $('ul.categoryfilter li.filter').on('click', function(event) {
          event.preventDefault();
          /* Act on the event */
          var category = $(this).attr('data-category');
          console.log(category);
          $('ul.categoryfilter li.filter').removeClass('active');
          $(this).addClass('active');
          $('.filterable').hide();
          $('.filterable.cat_'+category).show();
        });

        $('.searchtoggle').on('click', function(event) {
          event.preventDefault();
          /* Act on the event */
          $('.toparea .menubar').addClass('fixed');
          if ( $(this).hasClass('active')) {
            if ( $(window).scrollTop() > 10 ) {
            } else {
              $('.toparea .menubar').removeClass('fixed');
            }
          }
          $('.searchtoggle').toggleClass('active');
          $('.searchbar').toggleClass('active');
          $('input.search-field').focus();
        });


        $(window).scroll( function(){

          if( $(window).scrollTop() > 10 ) {
            $('.toparea .menubar').addClass('fixed');
          } else {
            $('.toparea .menubar').removeClass('fixed');
          }

        });
        $(window).load( function() {
          window.dispatchEvent(new Event('scroll'));
        });

        $('.wpcf7-form input').on('focus', function(event) {
          $(this).addClass('active');
          $(this).parent().parent().find('label').addClass('active');
        });

        $('.wpcf7-form textarea').on('focus', function(event) {
          $(this).addClass('active');
          $(this).parent().parent().find('label').addClass('active');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        function getAllUrlParams(url) {
          // get query string from url (optional) or window
          var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

          // we'll store the parameters here
          var obj = {};

          // if query string exists
          if (queryString) {

            // stuff after # is not part of query string, so get rid of it
            queryString = queryString.split('#')[0];

            // split our query string into its component parts
            var arr = queryString.split('&');

            for (var i=0; i<arr.length; i++) {
              // separate the keys and the values
              var a = arr[i].split('=');

              // in case params look like: list[]=thing1&list[]=thing2
              var paramNum = undefined;
              var paramName = a[0];

              // set parameter value (use 'true' if empty)
              var paramValue = typeof(a[1])==='undefined' ? true : a[1];

              // (optional) keep case consistent
              paramName = paramName.toLowerCase();
              paramValue = paramValue.toLowerCase();

              // if parameter name already exists
              if (obj[paramName]) {
                // convert value to array (if still string)
                if (typeof obj[paramName] === 'string') {
                  obj[paramName] = [obj[paramName]];
                }
                // if no array index number specified...
                if (typeof paramNum === 'undefined') {
                  // put the value on the end of the array
                  obj[paramName].push(paramValue);
                }
                // if array index number specified...
                else {
                  // put the value at that index number
                  obj[paramName][paramNum] = paramValue;
                }
              }
              // if param name doesn't exist yet, set it
              else {
                obj[paramName] = paramValue;
              }
            }
          }

          return obj;
        }

        if ( getAllUrlParams().filter ) {
          var value = getAllUrlParams().filter;
          $('ul.categoryfilter li.filter[data-category='+value+']').click();
        }



        jQuery('#newsyear').change(function() {
          var selected = jQuery(this).val();
          console.log(selected);
          jQuery('.year_all').hide();
          jQuery('.'+selected).show();

        });



        jQuery('.upcoming .filterdropdown').change(function() {
          var selected = jQuery(this).val();
          jQuery(this).parent().find('.booklist li.'+selected).show();
        });



        jQuery('.upcoming_archive .filterdropdown').change(function() {
          var selected = jQuery(this).val();
          var selectedCourse = jQuery(this).closest('.upcoming_archive').find('.filterdropdowncourse').val();
          
          jQuery('.filterbar .fc-filterbutton.city_'+selected).click();

          jQuery(this).closest('.upcoming_archive').find('.booklist li').hide();
          jQuery(this).closest('.upcoming_archive').find('.booklist li.'+selected+'.'+selectedCourse).css('display', 'flex');
          jQuery(this).closest('.upcoming_archive').find('.morebutton').hide();

        });

        jQuery('.upcoming_archive .filterdropdowncourse').change(function() {
          var selected = jQuery(this).val();
          var selectedCity = jQuery(this).closest('.upcoming_archive').find('.filterdropdown').val();

          jQuery(this).closest('.upcoming_archive').find('.booklist li').removeClass('moretoggle').hide();
          jQuery(this).closest('.upcoming_archive').find('.booklist li').hide();
          jQuery(this).closest('.upcoming_archive').find('.booklist li.'+selected+'.'+selectedCity).css('display', 'flex');

          jQuery(this).closest('.upcoming_archive').find('.morebutton').hide();

        });

        jQuery('.upcoming .filterdropdown').change(function() {
          var selected = jQuery(this).val();
          
          jQuery('.filterbar .fc-filterbutton.city_'+selected).click();

          jQuery(this).closest('.upcoming').find('.booklist li').hide();
          jQuery(this).closest('.upcoming').find('.booklist li.'+selected).css('display', 'flex');
          jQuery(this).closest('.upcoming').find('.morebutton').hide();

        });

        // Initialize chosen
        jQuery(".excel-dropdown").chosen({disable_search_threshold: 500, width: "100%"});

        // Initialize flickity
        jQuery('.news-carousel').flickity({
          // options
          wrapAround: true,
          autoPlay: 6000,
          pauseAutoPlayOnHover: true,
          prevNextButtons: true,
          cellAlign: 'left',
          contain: true,
          dragThreshold: 20
        });
        var $flickityobject = jQuery('.main-carousel').flickity({
          // options
          wrapAround: true,
          autoPlay: 6000,
          pauseAutoPlayOnHover: true,
          prevNextButtons: true,
          cellAlign: 'left',
          contain: true,
          dragThreshold: 20
        });
        jQuery($flickityobject).flickity('resize');

        var $customerscroll = jQuery('.customercarousel.enabled').flickity({
          // options
          wrapAround: true,
          autoPlay: 6000,
          pauseAutoPlayOnHover: false,
          prevNextButtons: false,
          pageDots: false,
          cellAlign: 'left',
          contain: true,
          dragThreshold: 20
        });
        jQuery($customerscroll).flickity('resize');
        jQuery(window).load( function() {
          window.dispatchEvent(new Event('resize'));
        });

        // Closes the modal window
        jQuery('.modalclose').on('click', function(event) {
          event.preventDefault();
          jQuery('.modalcover').hide();
          jQuery('.wpcf7-response-output.wpcf7-display-none.wpcf7-mail-sent-ok').hide();
        });
        jQuery('.modalholder').on('click', function(event) {
          if($(event.target).closest('.modalwindow').length === 0) {
            event.preventDefault();
            jQuery('.modalclose').click();
          }
        });

        // Spawns modal contact form
        jQuery('.contactfooter').on('click', function(event) {
          event.preventDefault();
          jQuery('.modalcover').css('display', 'table');
        });
        jQuery('#menu-huvudmeny>li:last-child a').on('click', function(event) {

          if ($('#mobiledetector').is(':visible') ) {

          } else {
            event.preventDefault();
            jQuery('.modalcover').css('display', 'table');
          }
        });
        jQuery('.customcoursebook').on('click', function(event) {
          event.preventDefault();
          jQuery('.modalcover').css('display', 'table');
        });

        jQuery('.navbar-toggle').on('click', function(event) {
          event.preventDefault();
          jQuery(this).toggleClass('open');
          jQuery('body').toggleClass('menuopen');
          jQuery('.headerlinks').toggleClass('visible');
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        
      }
    },
    // About us page, note the change from about-us to about_us.
    'boka': {
      init: function() {
        // JavaScript to be fired on the boka page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.